<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>

    <v-dialog v-model="confirmCreateInvoiceDialog" width="500">
      <v-card elevation="1">
        <v-card-title class="pr-1">
          ยืนยันการสร้างใบแจ้งหนี้
          <v-spacer/>
          <v-btn icon @click="confirmCreateInvoiceDialog=false"><v-icon class="fn-25">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text class="pl-7 pb-0">
          <v-row class="py-1">
            <v-col cols="6">
              <strong>ชื่อร้าน</strong> :
            </v-col>
            <v-col cols="6" align="right">
              <span v-if="shop.selected_to_make_invoice.length > 0">{{shop.selected_to_make_invoice[0]['Name']}}</span>
            </v-col>
          </v-row>
          <v-row class="py-1">
            <v-col cols="6">
              <strong>ค่าส่ง</strong> :
            </v-col>
            <v-col cols="6" align="right">{{formatMoney(dataConfirmCreateInvoice.sendPriceMakeInvoice)}}</v-col>
          </v-row>
          <v-row class="py-1">
            <v-col cols="6">
              <strong>ค่าพื้นที่ห่างไกล</strong> :
            </v-col>
            <v-col cols="6" align="right">{{formatMoney(dataConfirmCreateInvoice.remotePriceMakeInvoice)}}</v-col>
          </v-row>
          <v-row class="py-1">
            <v-col cols="6">
              <strong>ค่าCOD</strong> :
            </v-col>
            <v-col cols="6" align="right">{{formatMoney(dataConfirmCreateInvoice.codPriceMakeInvoice)}}</v-col>
          </v-row>
          <v-row class="py-1">
            <v-col cols="6">
              <strong>ค่ากล่อง</strong> :
            </v-col>
            <v-col cols="6" align="right">{{formatMoney(dataConfirmCreateInvoice.boxPriceMakeInvoice)}}</v-col>
          </v-row>
          <v-row class="py-1">
            <v-col cols="6">
              <strong>ค่าแพ็ค</strong> :
            </v-col>
            <v-col cols="6" align="right">{{formatMoney(dataConfirmCreateInvoice.packPriceMakeInvoice)}}</v-col>
          </v-row>
          <v-row class="py-1">
            <v-col cols="6">
              <strong>ส่วนลด</strong> :
            </v-col>
            <v-col cols="6" align="right">{{formatMoney(dataConfirmCreateInvoice.discountMakeInvoice)}}</v-col>
          </v-row>
          <v-row class="py-1">
            <v-col cols="6">
              <strong>ราคารวม</strong> :
            </v-col>
            <v-col cols="6" align="right" style="color: red;">{{formatMoney(Number(parseFloat(dataConfirmCreateInvoice.sendPriceMakeInvoice)+parseFloat(dataConfirmCreateInvoice.remotePriceMakeInvoice)+parseFloat(dataConfirmCreateInvoice.codPriceMakeInvoice)+parseFloat(dataConfirmCreateInvoice.boxPriceMakeInvoice)+parseFloat(dataConfirmCreateInvoice.packPriceMakeInvoice)).toFixed(2))}}</v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="mx-2">
          <v-spacer/>
          <v-btn dark color="primary" class="my-1" v-show="shop.selected_to_make_invoice.length>0" @click="createInvoice()"><v-icon class="ma-1">mdi-content-save</v-icon>สร้างใบแจ้งหนี้</v-btn>
          <v-spacer/>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="listOrderInvoiceDialog" >
      <v-card elevation="1">
        <v-card-title>
          <font v-if="listOrderInvoice.table.length>0">ข้อมูลใบแจ้งหนี้ : INV{{FormatInvoice(listOrderInvoice.invoiceId)}}
          <br class="hidden-sm-and-up">  (เวลาออกใบเสร็จ {{listOrderInvoice.table[0]['IV_CreatedDatetime']}})</font>
          <v-btn icon @click="listOrderInvoice_export_excel()"><v-icon color="success">mdi-file-excel</v-icon></v-btn>
          <v-spacer/>
          <v-btn icon @click="listOrderInvoiceDialog=false, invoice_id_row =false"><v-icon class="fn-25">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text style="height: 780px; background: #f0f0f0;" class="overflow-y-auto">


          <!-- ที่อยู่ Branch -->

          <v-card elevation="0" class="my-0 my-10">
            <v-card-title >
              <span v-if="branchDisibled == true">ที่อยู่ใบกำกับภาษีสาขา</span>
              <span v-else>แก้ไขที่อยู่ใบกำกับภาษีสาขา</span>
              <v-spacer></v-spacer>
              <v-btn icon v-if="branchDisibled == true" @click="branchDisibled = !branchDisibled"><v-icon size="18" >fa-edit</v-icon></v-btn>
              <v-btn icon v-else @click="branchDisibled = !branchDisibled"><v-icon size="18" >fa-edit</v-icon></v-btn>
            </v-card-title>
            <v-divider/>

            <!-- แสดงที่อยู่ branch -->
            <v-card-text v-if="branchDisibled == true">
              <v-row row >
                <v-col class="pb-0" cols="12">
                  {{branch_FullAddress_text}}
                </v-col>
              </v-row>
            </v-card-text>

            <!-- แก้ไขที่อยู่ branch -->
            <v-card-text v-else>
              <v-row row >
                <v-col class="pb-2" cols="12" md="12">
                  <v-row row >
                    <v-col class="pb-2" cols="12" md="3" lg="2">
                    <h4>  ชื่อบุคคล/นิติบุคคล :</h4>
                    </v-col>
                    <v-col class="pb-2" cols="12" md="6" lg="4">
                      <v-text-field
                        :value="BranchAddressInvoice.Name"
                        @change="value => BranchAddressInvoice.Name = value"
                        autocomplete="off"
                        ref="refBranchAddressInvoiceName"
                        outlined
                        hide-details
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row row >
                <v-col class="pb-2" cols="12" md="12">
                  <v-row row >
                    <v-col class="pb-2" cols="12" md="3" lg="2">
                      <h4>เลขผู้เสียภาษี :</h4>
                    </v-col>
                    <v-col class="pb-2" cols="12" md="6" lg="4">
                      <v-text-field
                        :value="BranchAddressInvoice.IDCardNumber"
                        @change="value => BranchAddressInvoice.IDCardNumber = value"
                        autocomplete="off"
                        ref="refBranchAddressInvoiceIDCardNumber"
                        maxlength="10"
                        outlined
                        hide-details
                        @keypress="isNumberWNoDot($event)"
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row row >
                <v-col class="pb-2" cols="12" md="12">
                  <v-row row >
                    <v-col class="pb-2" cols="12" md="3" lg="2">
                      <h4>เบอร์โทร :</h4>
                    </v-col>
                    <v-col class="pb-2" cols="12" md="6" lg="4">
                      <v-text-field
                        :value="BranchAddressInvoice.Phone"
                        @change="value => BranchAddressInvoice.Phone = value"
                        autocomplete="off"
                        ref="refBranchAddressInvoicePhone"
                        maxlength="10"
                        outlined
                        hide-details
                        @keypress="isNumberWNoDot($event)"
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row row >
                <v-col class="pb-2" cols="12" md="12">
                  <v-row row >
                    <v-col class="pb-2" cols="12" md="3" lg="2">
                    <h4>  ที่อยู่ :</h4>
                    </v-col>
                    <v-col class="pb-2" cols="12" md="6" lg="4">
                      <v-text-field
                        :value="BranchAddressInvoice.TextAddress"
                        @change="value => BranchAddressInvoice.TextAddress = value"
                        autocomplete="off"
                        ref="refBranchAddressInvoiceTextAddress"
                        outlined
                        hide-details
                        dense

                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row row >
                <v-col class="pb-2" cols="12" md="12">
                  <v-row row >
                    <v-col class="pb-2" cols="12" md="3" lg="2">
                      <h4>รหัสไปรษณีย์ :</h4>
                    </v-col>
                    <v-col class="pb-2" cols="12" md="6" lg="4">
                      <v-autocomplete
                        :value="BranchAddressInvoice.Address"
                        @change="value => BranchAddressInvoice.Address = value"
                        autocomplete="off"
                        ref="refBranchAddressInvoiceAddress"
                        placeholder="รหัสไปรษณีย์ / จังหวัด / (เขต/อำเภอ) / (แขวง/ตำบล)"
                        :items="postcode_mapping"
                        :item-text="item => item.Postcode + ' ' + item.Province + ' ' + item.District + ' ' + item.SubDistrict"
                        outlined
                        required
                        return-object
                        hide-details
                        dense

                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-2" cols="12" md="12">
                  <v-row row >
                    <v-col class="pb-2" cols="12" md="12" align="left">
                      <v-btn dark color="primary" @click="AddandEditBranchAddressInvoice('ADD')" class="mr-1"><v-icon left >mdi-content-save</v-icon> บันทึก</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <!-- ที่อยู่ร้านค้า -->
          <v-card elevation="0" class="my-0">
            <v-card-title >
              <span v-if="shopDisibled == true">ที่อยู่ใบกำกับภาษีร้านค้า</span>
              <span v-else>แก้ไขที่อยู่ใบกำกับภาษีร้านค้า</span>
              <v-spacer></v-spacer>
              <v-btn icon v-if="shopDisibled == true" @click="shopDisibled = !shopDisibled"><v-icon size="18" >fa-edit</v-icon></v-btn>
              <v-btn icon v-else @click="shopDisibled = !shopDisibled"><v-icon size="18" >fa-edit</v-icon></v-btn>
            </v-card-title>
            <v-divider/>

            <!-- แสดงที่อยู่ shop -->
            <v-card-text v-if="shopDisibled == true">
              <v-row row >
                <v-col class="pb-0" cols="12">
                  {{Shop_FullAddress_text}}
                </v-col>
              </v-row>
            </v-card-text>

            <!-- แก้ไขที่อยู่ shop -->
            <v-card-text v-else>
              <v-row row >
                <v-col class="pb-2" cols="12" md="12">
                  <v-row row >
                    <v-col class="pb-2" cols="12" md="3" lg="2">
                    <h4>  ชื่อบุคคล/นิติบุคคล :</h4>
                    </v-col>
                    <v-col class="pb-2" cols="12" md="6" lg="4">
                      <v-text-field
                        :value="ShopAddressInvoice.Name"
                        @change="value => ShopAddressInvoice.Name = value"
                        autocomplete="off"
                        ref="refShopAddressInvoiceName"
                        outlined
                        hide-details
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row row >
                <v-col class="pb-2" cols="12" md="12">
                  <v-row row >
                    <v-col class="pb-2" cols="12" md="3" lg="2">
                      <h4>เลขผู้เสียภาษี :</h4>
                    </v-col>
                    <v-col class="pb-2" cols="12" md="6" lg="4">
                      <v-text-field
                        :value="ShopAddressInvoice.IDCardNumber"
                        @change="value => ShopAddressInvoice.IDCardNumber = value"
                        autocomplete="off"
                        ref="refShopAddressInvoiceIDCardNumber"
                        maxlength="10"
                        outlined
                        hide-details
                        @keypress="isNumberWNoDot($event)"
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row row >
                <v-col class="pb-2" cols="12" md="12">
                  <v-row row >
                    <v-col class="pb-2" cols="12" md="3" lg="2">
                      <h4>เบอร์โทร :</h4>
                    </v-col>
                    <v-col class="pb-2" cols="12" md="6" lg="4">
                      <v-text-field
                        :value="ShopAddressInvoice.Phone"
                        @change="value => ShopAddressInvoice.Phone = value"
                        autocomplete="off"
                        ref="refShopAddressInvoicePhone"
                        maxlength="10"
                        outlined
                        hide-details
                        @keypress="isNumberWNoDot($event)"
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row row >
                <v-col class="pb-2" cols="12" md="12">
                  <v-row row >
                    <v-col class="pb-2" cols="12" md="3" lg="2">
                    <h4>  ที่อยู่ :</h4>
                    </v-col>
                    <v-col class="pb-2" cols="12" md="6" lg="4">
                      <v-text-field
                        :value="ShopAddressInvoice.TextAddress"
                        @change="value => ShopAddressInvoice.TextAddress = value"
                        autocomplete="off"
                        ref="refShopAddressInvoiceTextAddress"
                        outlined
                        hide-details
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row row >
                <v-col class="pb-2" cols="12" md="12">
                  <v-row row >
                    <v-col class="pb-2" cols="12" md="3" lg="2">
                      <h4>รหัสไปรษณีย์ :</h4>
                    </v-col>
                    <v-col class="pb-2" cols="12" md="6" lg="4">
                      <v-autocomplete
                        v-model="ShopAddressInvoice.Address"
                        ref="refShopAddressInvoiceAddress"
                        placeholder="รหัสไปรษณีย์ / จังหวัด / (เขต/อำเภอ) / (แขวง/ตำบล)"
                        :items="postcode_mapping"
                        :item-text="item => item.Postcode + ' ' + item.Province + ' ' + item.District + ' ' + item.SubDistrict"
                        outlined
                        required
                        return-object
                        hide-details
                        dense

                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-2" cols="12" md="12">
                  <v-row row >
                    <v-col class="pb-2" cols="12" md="12" align="left">
                      <v-btn dark color="primary" @click="AddandEditShopAddressInvoice('ADD')" class="mr-1"><v-icon left >mdi-content-save</v-icon> บันทึก</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <!-- ข้อมูล order PC -->
          <v-card elevation="0" class="my-0 my-10">
            <v-card-title >
              <span class="my-2">ข้อมูลออเดอร์</span>
            </v-card-title>
            <v-divider/>

            <v-card-text >
              <span>ร้านค้า :  {{ ShopDetailInvoiceText }}</span>
            </v-card-text >
            <div class="overflow-y-auto hidden-xs-only">
              <v-data-table
                :headers="listOrderInvoice.headers"
                :key="listOrderInvoice.key_table"
                :items="listOrderInvoice.table"
                hide-default-footer :items-per-page="10000"
                item-key="ID"
                class="elevation-0 packhai-border-table"
                >
                <template v-slot:item.TotalPrice="{ item }">
                  {{calculateTotal(item)}}
                </template>
                <template v-slot:item.ID="{ item }">
                  <font v-if="item.ID!=''&&item.ID!=null">{{FormatOrder(item.ID)}}</font>
                  <font v-else>รวม</font>
                </template>
              </v-data-table>
            </div>

            <!-- จอ มือถือ -->
            <v-simple-table show-select class="hidden-sm-and-up packhai-border-table" >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" style="min-width: 150px;">
                      ออเดอร์
                    </th>
                    <th class="text-left" style="min-width: 150px;">
                      เลขแทรค
                    </th>
                    <th class="text-right" style="min-width: 100px;">
                      ค่าส่ง
                    </th>
                    <th class="text-right" style="min-width: 150px;">
                      ค่าพื้นที่ห่างไกล
                    </th>
                    <th class="text-right" style="min-width: 100px;">
                      ค่า COD
                    </th>
                    <th class="text-right" style="min-width: 100px;">
                      ค่ากล่อง
                    </th>
                    <th class="text-right" style="min-width: 100px;">
                      ค่าแพค
                    </th>
                    <th class="text-right" style="min-width: 100px;">
                      ส่วนลด
                    </th>
                    <th class="text-right" style="min-width: 150px;">
                      รวม
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in listOrderInvoice.table" :key="index" >
                    <td class="text-left">
                      <span v-if="item.ID!=''&&item.ID!=null">{{FormatOrder(item.ID)}}</span>
                      <span v-else>รวม</span>
                    </td>
                    <td class="text-left">{{ item.TrackingNo }}</td>
                    <td class="text-right">{{ item.DeliveryPrice }}</td>
                    <td class="text-right">{{ item.RemotePrice }}</td>
                    <td class="text-right">{{ item.CODCharged }}</td>
                    <td class="text-right">{{ item.BoxPrice }}</td>
                    <td class="text-right">{{ item.PackPrice }}</td>
                    <td class="text-right">{{ item.Discount }}</td>
                    <td class="text-right">{{ calculateTotal(item) }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <loading :loading="loading" />

    <v-card elevation="0" class="v-card-margin-bottom-30">
      <v-card-title v-if="navigete!=null && navigete!='' && navigete!='null'">
        การเงิน <v-icon>mdi-chevron-right</v-icon>
        {{ navigete[0]['title'] }}
      </v-card-title>
    </v-card>

    <v-tabs v-model="tabSelected" background-color="red lighten-1" dark>
      <v-tab v-for="item in tabList" :key="item">
        {{ item }}
      </v-tab>
    </v-tabs>

    <v-card class="v-card-margin-bottom-30">
      <v-card-title>
        <v-icon size="20" color="primary">fa-sliders-h</v-icon><span class="my-3 ml-3">ค้นหารายการ</span>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <v-row class="mx-0 pb-3" v-if="tabSelected == 0">
          <v-col cols="12" class="hidden-sm-and-up py-3 pl-0 pb-0">
            <span><b> เลือกร้านค้า : </b></span>
          </v-col>
          <v-col cols="12" md="5" class="py-3 pl-0 pb-0" style="display: flex;">
            <span class="py-3 mr-2 hidden-xs-only"><b> เลือกร้านค้า : </b></span>
            <v-select v-model="shop.shop_select" :items="shop.list" :item-text="item => item.Name" outlined return-object hide-details ></v-select>
          </v-col>
          <v-col cols="3" md="3" class="py-4 pl-0 pb-0">
            <v-btn dark color="error" @click="selectedShop()" class="fn-14"><v-icon>mdi-magnify</v-icon>ค้นหา</v-btn>
          </v-col>
        </v-row>
        <div v-else >
        <v-row>
          <v-col cols="12" md="2" lg="1" class=" pb-2">
            <span><b> เลือกวันที่ : </b></span>
          </v-col>
          <v-col cols="12" md="3" lg="2" class="  pb-2 ">
            <v-menu
              ref="menuStartDate"
              v-model="menuStartDate"
              :close-on-content-click="false"
              :return-value.sync="startDate"
              transition="scale-transition"
              offset-y
              min-width="290px"
              max-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  outlined
                  :value="startDate"
                  @change="value => startDate = value"
                  autocomplete="off"
                  label="วันที่เริ่มต้น"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-on="on"
                  hide-details

                ></v-text-field>
              </template>
              <v-date-picker v-model="startDate" no-title scrollable :allowed-dates="allowedDates">
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12"   md="3" lg="2" class="  pb-2 ">
            <v-menu
              ref="menuEndDate"
              v-model="menuEndDate"
              :close-on-content-click="false"
              :return-value.sync="endDate"
              transition="scale-transition"
              offset-y
              min-width="290px"
              max-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  outlined
                  :value="endDate"
                  @change="value => endDate = value"
                  autocomplete="off"
                  label="วันที่สิ้นสุด"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-on="on"
                  hide-details

                ></v-text-field>
              </template>
              <v-date-picker v-model="endDate" no-title scrollable :allowed-dates="allowedDates">
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="2" lg="1" class="pb-2 ">
            <span><b> เลือกร้านค้า : </b></span>
          </v-col>
          <v-col cols="12" md="6" lg="4"  class="pb-2" style="display: flex;">
            <!-- <span class="py-3 mr-2 hidden-xs-only"><b> เลือกร้านค้า : </b></span> -->
            <v-select
              v-model="search_shops"
              :items="shop.list"
              :item-text="item => item.Name"
              :item-value="item => item.ID "
              outlined
              dense
              hide-details
              return-object>
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="2" lg="1" class="pb-2 ">
          </v-col>
          <v-col cols="12"  md="6" lg="4"   class="pb-2">
            <v-btn dark color="error" @click="getInvoice()" class="fn-14"><v-icon>mdi-magnify</v-icon>ค้นหา</v-btn>
          </v-col>
        </v-row>
      </div>
      </v-card-text>
    </v-card>

    <v-card>
      <v-card-title>
          <v-icon v-show="tabSelected == 0" size="20" color="success">fa-receipt</v-icon><span v-show="tabSelected == 0" class="my-3 ml-3">รายการออเดอร์</span>
          <!-- <v-btn color="success" v-show="requestTopup.table.length > 0" @click="export_to_excel()" ><v-icon left size="20">mdi-file-excel</v-icon>export to excel</v-btn> -->
          <v-icon v-show="tabSelected == 1" size="20" color="success">fa-receipt</v-icon><span v-show="tabSelected == 1" class="my-3 ml-3">รายการใบแจ้งหนี้</span>
          <v-spacer/>
          <v-btn v-if="shop.selected_to_make_invoice.length>0" class="ma-1" color="primary" @click="confirmCreateInvoice()">สร้างใบแจ้งหนี้</v-btn>
          <!-- <v-btn v-show="tabSelected == 0" outlined color="primary" class="ma-1 fn-14" @click="AutoCreateInvoiceAllShop_confirm()">สร้างใบแจ้งหนี้อัตโนมัติ</v-btn> -->
          <v-btn v-show="tabSelected == 1 && invoice.table.length != 0" color="success" outlined @click="Export_to_excel_data_invoice()">Export to Excel</v-btn>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <v-tabs-items v-model="tabSelected" touchless>
          <!-- datatable tap1 -->
          <v-tab-item>
            <v-row class="mt-2 mx-0">
              <v-col cols="12" class=" px-0">
                <v-row class="pt-0 mx-0">
                  <v-col cols="12" md="6" class="mb-2">
                     จำนวนแถว :
                     <select @change="set_row_per_page()" v-model="row_per_page_select_tmp" style="color: black; border: solid 1px red; padding-left: 10px; cursor: pointer;">
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                        <option value="500">500</option>
                        <option value="10000000">ทั้งหมด</option>
                      </select>
                     / หน้า
                  </v-col>
                </v-row>

                <!-- PC -->
                <v-data-table
                  v-model="shop.selected_to_make_invoice"
                  :headers="shop.headers"
                  :key="shop.key_table"
                  :items="shop.table"
                  :items-per-page="row_per_page_select"
                  hide-default-footer
                  item-key="ID"
                  :page ="page"
                  show-select
                  class="elevation-0 packhai-border-table hidden-xs-only"
                  >
                  <template v-slot:item.TotalPrice="{ item }">
                    {{calculateTotal(item)}}
                  </template>
                  <template v-slot:item.ID="{ item }">
                    <font>{{FormatOrder(item.ID)}}</font>
                  </template>
                </v-data-table>

                <!-- Mobile -->
                <!-- {{ checkboxList }} -->
                <v-simple-table show-select class="hidden-sm-and-up packhai-border-table" >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left" style="min-width: 50px;">
                          <v-checkbox
                            v-model="checkboxAll"
                            color="primary"
                            value="1"
                            @change="SelectItem('ALL', null, null, null)"
                            hide-details>
                          </v-checkbox>
                        </th>
                        <th class="text-left" style="min-width: 100px;">
                          ชื่อร้าน
                        </th>
                        <th class="text-left" style="min-width: 200px;">
                          เวลา/วันที่
                        </th>
                        <th class="text-left" style="min-width: 100px;">
                          ออเดอร์
                        </th>
                        <th class="text-right" style="min-width: 100px;">
                          เลขเทรค
                        </th>
                        <th class="text-right" style="min-width: 100px;">
                          ค่าส่ง
                        </th>
                        <th class="text-right" style="min-width: 150px;">
                          ค่าพื้นที่ห่างไกล
                        </th>
                        <th class="text-right" style="min-width: 100px;">
                          ค่า COD
                        </th>
                        <th class="text-right" style="min-width: 100px;">
                          ค่ากล่อง
                        </th>
                        <th class="text-right" style="min-width: 100px;">
                          ค่าแพค
                        </th>
                        <th class="text-right" style="min-width: 100px;">
                          ส่วนลด
                        </th>
                        <th class="text-right" style="min-width: 150px;">
                          ราคารวม
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in shop.table" :key="index" >
                        <td>
                          <v-checkbox
                            v-model="checkboxList[index]"
                            color="primary"
                            value="1"
                            @change="SelectItem(null ,shop.table[index], index, checkboxList[index])"
                            hide-details>
                          </v-checkbox>
                        </td>
                        <td class="text-left">{{item.Name}}</td>
                        <td class="text-left">{{ item.SendToExpressDatetime }}</td>
                        <td class="text-left"><font>{{FormatOrder(item.ID)}}</font></td>
                        <td class="text-right">{{ item.TrackingNo }}</td>
                        <td class="text-right">{{ item.DeliveryPrice }}</td>
                        <td class="text-right">{{ item.RemotePrice }}</td>
                        <td class="text-right">{{ item.CODCharged }}</td>
                        <td class="text-right">{{ item.BoxPrice }}</td>
                        <td class="text-right">{{ item.PackPrice }}</td>
                        <td class="text-right">{{ item.Discount }}</td>
                        <td class="text-right">{{ calculateTotal(item) }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

                <div class="text-center mt-2">
                    <v-pagination
                      v-model="page"
                      :length="pageLength"
                      :total-visible="10"
                    ></v-pagination>
                  </div>
              </v-col>
            </v-row>
          </v-tab-item>
          <!-- datatable tap2 -->
          <v-tab-item>
            <v-row class="mt-2 mx-0">
              <v-col cols="12" class=" px-0">
                <!-- PC -->
                <v-data-table
                  :headers="invoice.headers"
                  :key="invoice.key_table"
                  :items="invoice.table"
                  :footer-props="shop.footer_props"
                  item-key="ID"
                  class="elevation-0 packhai-border-table hidden-xs-only"
                  >
                  <template v-slot:item.ID="{ item }">
                    <a @click="openListOrderInvoice(item), shopDisibled = true, branchDisibled = true">INV{{FormatInvoice(item.ID)}}</a>
                  </template>
                  <template v-slot:item.total="{ item }">
                    {{Number(calculateTotal(item)).toLocaleString(undefined, {minimumFractionDigits: 2})}}
                  </template>
                  <template v-slot:item.DeliveryPrice="{ item }">
                    {{Number(item.DeliveryPrice).toLocaleString(undefined, {minimumFractionDigits: 2})}}
                  </template>
                  <template v-slot:item.RemotePrice="{ item }">
                    {{Number(item.RemotePrice).toLocaleString(undefined, {minimumFractionDigits: 2})}}
                  </template>
                  <template v-slot:item.CODCharged="{ item }">
                    {{Number(item.CODCharged).toLocaleString(undefined, {minimumFractionDigits: 2})}}
                  </template>
                  <template v-slot:item.BoxPrice="{ item }">
                    {{Number(item.BoxPrice).toLocaleString(undefined, {minimumFractionDigits: 2})}}
                  </template>
                  <template v-slot:item.PackPrice="{ item }">
                    {{Number(item.PackPrice).toLocaleString(undefined, {minimumFractionDigits: 2})}}
                  </template>
                  <template v-slot:item.Discount="{ item }">
                    {{Number(item.Discount).toLocaleString(undefined, {minimumFractionDigits: 2})}}
                  </template>
                  <template v-slot:item.CancelInvoice="{ item }">
                    <v-btn icon @click="CancelInvoiceConfirm(item.ID)"><v-icon color="red" size="18" >fa-trash-alt</v-icon></v-btn>
                  </template>
                  <template v-slot:item.Print="{ item }">
                      <v-btn icon @click="PrintPreview(item['ID'])"><v-icon color="success">mdi-printer</v-icon></v-btn>
                  </template>
                </v-data-table>

                <!-- Mobile -->
                <v-simple-table show-select class="hidden-sm-and-up packhai-border-table" >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left" style="min-width: 150px;">
                          Invoice No
                        </th>
                        <th class="text-left" style="min-width: 200px;">
                          วันที่สร้าง
                        </th>
                        <th class="text-left" style="min-width: 220px;">
                          สร้างโดย
                        </th>
                        <th class="text-left" style="min-width: 250px;">
                          ร้านค้า
                        </th>
                        <th class="text-right" style="min-width: 100px;">
                          ค่าส่ง
                        </th>
                        <th class="text-right" style="min-width: 150px;">
                          ค่าพื้นที่ห่างไกล
                        </th>
                        <th class="text-right" style="min-width: 100px;">
                          ค่า COD
                        </th>
                        <th class="text-right" style="min-width: 100px;">
                          ค่ากล่อง
                        </th>
                        <th class="text-right" style="min-width: 100px;">
                          ค่าแพค
                        </th>
                        <th class="text-right" style="min-width: 100px;">
                          ส่วนลด
                        </th>
                        <th class="text-right" style="min-width: 150px;">
                          รวม
                        </th>
                        <th class="text-center" style="min-width: 100px;">
                          ยกเลิก
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in invoice.table" :key="index" >
                        <td class="text-left"><a @click="openListOrderInvoice(item), shopDisibled = true, branchDisibled = true">INV{{FormatInvoice(item.ID)}}</a></td>
                        <td class="text-left">{{ item.CreatedDatetime }}</td>
                        <td class="text-left">{{ item.CreatedName}}</td>
                        <td class="text-left">{{ item.Name }}</td>
                        <td class="text-right">{{ item.DeliveryPrice }}</td>
                        <td class="text-right">{{ item.RemotePrice }}</td>
                        <td class="text-right">{{ item.CODCharged }}</td>
                        <td class="text-right">{{ item.BoxPrice }}</td>
                        <td class="text-right">{{ item.PackPrice }}</td>
                        <td class="text-right">{{ item.Discount }}</td>
                        <td class="text-right">{{ calculateTotal(item) }}</td>
                        <td class="text-center"><v-btn icon @click="CancelInvoiceConfirm(item.ID)"><v-icon color="red" size="18" >fa-trash-alt</v-icon></v-btn></td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import axios from 'axios'
  import Swal from 'sweetalert2'
  import { header_token } from '@/website/token'
  import Loading from '@/website/components/Loading'
  import { financialService, manageShopService, generalService_dotnet } from '@/website/global'
  import { AlertSuccess, AlertWarning, AlertError } from '@/website/global_alert'
  import { FormatInvoice, FormatOrder, formatMoney, isNumberWNoDot } from '@/website/global_function'
  export default {
    components: {
      Loading
    },
    data: () => ({
      page_loading: true,
      loading: false,

      navigete: [],

      // เปิด เลือกวันที่
      startDate: null,
      menuStartDate: false,
      checkStart: true,

      endDate: null,
      menuEndDate: false,
      checkEnd: true,
      // ปิด เลือกวันที่

      search_shops: null,

      confirmCreateInvoiceDialog: false,
      tabSelected: null,
      tabList: ['สร้างใบแจ้งหนี้', 'ใบแจ้งหนี้ทั้งหมด'],
      shop: {
        'headers': [
            { text: 'ชื่อร้าน', value: 'Name', sortable: false},
            { text: 'เวลา/วันที่', value: 'SendToExpressDatetime', sortable: false },
            { text: 'ออเดอร์', value: 'ID', sortable: false },
            { text: 'เลขแทรค', value: 'TrackingNo', sortable: false },

            { text: 'ค่าส่ง', value: 'DeliveryPrice', sortable: false, align: 'right' },
            { text: 'ค่าพื้นที่ห่างไกล', value: 'RemotePrice', sortable: false, align: 'right' },
            { text: 'ค่า COD', value: 'CODCharged', sortable: false, align: 'right' },
            { text: 'ค่ากล่อง', value: 'BoxPrice', sortable: false, align: 'right' },
            { text: 'ค่าแพค', value: 'PackPrice', sortable: false, align: 'right' },
            { text: 'ส่วนลด', value: 'Discount', sortable: false, align: 'right' },
            { text: 'ราคารวม', value: 'TotalPrice', sortable: false, align: 'right' },
          ],
        'table': [],
        'footer_props': {
           'items-per-page-options': [50],
           'items-per-page-text': null,
           'disable-items-per-page': true
        },
        'list': [],
        'shop_select': '',
        'selected_to_make_invoice': [],
        'key_table': 0
      },
      dataConfirmCreateInvoice: {
        'dataList': [],
        'sendPriceMakeInvoice': 0,
        'remotePriceMakeInvoice': 0,
        'codPriceMakeInvoice': 0,
        'boxPriceMakeInvoice': 0,
        'packPriceMakeInvoice': 0,
        'discountMakeInvoice': 0,
      },
      invoice: {
        'datetime_search': [new Date(new Date().setDate(new Date().getDate()-1)).toISOString().substr(0, 10),new Date(new Date().setDate(new Date().getDate())).toISOString().substr(0, 10)],
        'showDatetime': false,
        'headers': [
          { text: 'Invoice No', align: 'center', sortable: false, value: 'ID'},
          { text: 'วันที่สร้าง', align: 'center', sortable: false, value: 'CreatedDatetime'},
          { text: 'สร้างโดย', align: 'center', sortable: false, value: 'CreatedName'},
          { text: 'ร้านค้า', align: 'center', sortable: false, value: 'Name'},
          { text: 'ค่าส่ง', align: 'right', sortable: false, value: 'DeliveryPrice'},
          { text: 'ค่าพื้นที่ห่างไกล', align: 'right', sortable: false, value: 'RemotePrice'},
          { text: 'ค่า COD', align: 'right', sortable: false, value: 'CODCharged'},
          { text: 'ค่ากล่อง', align: 'right', sortable: false, value: 'BoxPrice'},
          { text: 'ค่าแพ็ค', align: 'right', sortable: false, value: 'PackPrice'},
          { text: 'ส่วนลด', value: 'Discount', sortable: false, align: 'right' },
          { text: 'รวม', align: 'right', sortable: false, value: 'total'},
          { text: 'ยกเลิก', align: 'center', sortable: false, value: 'CancelInvoice'},
          { text: 'ปริ้น', align: 'center', sortable: false, value: 'Print'},
        ],
        'key_table': 0,
        'table': []
      },
      listOrderInvoiceDialog: false,

      ShopDetailInvoiceText: null,
      listOrderInvoice: {
        'headers': [
            { text: 'ออเดอร์', align: 'center', value: 'ID', sortable: false },
            { text: 'เลขแทรค', align: 'center', value: 'TrackingNo', sortable: false },
            { text: 'ค่าส่ง', align: 'right', value: 'DeliveryPrice', sortable: false },
            { text: 'ค่าพื้นที่ห่างไกล', align: 'right', value: 'RemotePrice', sortable: false },
            { text: 'ค่า COD', align: 'right', value: 'CODCharged', sortable: false },
            { text: 'ค่ากล่อง', align: 'right', value: 'BoxPrice', sortable: false },
            { text: 'ค่าแพค', align: 'right', value: 'PackPrice', sortable: false },
            { text: 'ส่วนลด', value: 'Discount', sortable: false, align: 'right' },
            { text: 'ราคารวม', align: 'right', value: 'TotalPrice', sortable: false },
          ],
        'key_table': 0,
        'table': [],
        'invoiceId': ''
      },
      // paginations
      page: 1,
      page_tmp: null,
      row_per_page: 50,
      pageLength: null,

      row_per_page_select: 50,
      row_per_page_select_tmp: 50,
      row_per_page_list: [
        {text: '25', value: 25},
        {text: '50', value: 50},
        {text: '100', value: 100},
        {text: '200', value: 200},
        {text: '500', value: 500},
        {text: 'ทั้งหมด', value: 10000000},
      ],

      IsDataInvoice: null,
      reGetInvoice: 1,

      // ข้อมูล dialog invoice

      invoice_id_row: null,
      shopDisibled: true,
      branchDisibled: true,
      IsReadonly : false,
      ShopAddressType : null,
      BranchAddressType : null,
      dialog_invoice_invoiceID: null,
      dialog_invoice_ShopID : null,
      dialog_invoice_BranchID : null,
      postcode_mapping: null,

      // checkbox for data table
      checkboxList: [],
      checkboxAll: [],
      selected: [],



      // ออกใบกำกับภาษี shop
      Shop_FullAddress_text: null,
      ShopAddressInvoice: {
        "ID":null,
        "Name": null,
        "Phone": null,
        "IDCardNumber": null,
        "TextAddress": null,
        "Address": null,
        "PostcodeMappingID": null,
        "IsDefault": true,
      },

      ShopAddressInvoice_tmp: {
        "ID":null,
        "Name": null,
        "Phone": null,
        "IDCardNumber": null,
        "TextAddress": null,
        "Address": null,
        "PostcodeMappingID": null,
        "IsDefault": true,
      },

      // ออกใบกำกับภาษี branch
      branch_FullAddress_text: null,
      BranchAddressInvoice: {
        "ID":null,
        "Name": null,
        "Phone": null,
        "IDCardNumber": null,
        "TextAddress": null,
        "Address": null,
        "PostcodeMappingID": null,
        "IsDefault": true,
      },

      BranchAddressInvoice_tmp: {
        "ID":null,
        "Name": null,
        "Phone": null,
        "IDCardNumber": null,
        "TextAddress": null,
        "Address": null,
        "PostcodeMappingID": null,
        "IsDefault": true,
      },

    }),
    async created() {
      this.navigete = JSON.parse(localStorage.getItem('navigete'))
      await this.get_postcode_mapping()
      let response = await axios.post(financialService+'financialFC/getShop', {
        'BranchID': localStorage.getItem('Branch_BranchID')
      }, {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('token')}} )
      this.loading = false
      if (response.data.status == 'Success') {
        this.shop['list'] = response.data.result
        this.shop['key_table']++
      }
      this.shop['shop_select'] = this.shop['list'][0]
      // this.selectedShop()
      this.page_loading = false
    },
    computed: {
      computed_search_sent_date() {
        if(this.date_range[0] != null) {
          return this.formatDate(this.date_range)
        } else {
          return null
        }
      },
    },
    watch: {
      tabSelected: async function() {
        this.loading = true
        if(this.tabSelected==0){
          this.IsDataInvoice = 0
          // clearData
          this.checkboxList = []
          this.checkboxAll = []
          this.selected = []
          this.selectedShop()
        }else if(this.tabSelected==1){
          // this.invoice['datetime_search'] = [new Date(new Date().setDate(new Date().getDate()-1)).toISOString().substr(0, 10),new Date(new Date().setDate(new Date().getDate())).toISOString().substr(0, 10)]
          this.IsDataInvoice = 1
          this.getInvoice()
        }
      },
      startDate: async function(newVal, oldVal) {
        if (new Date(newVal)>=new Date(this.endDate)){
          this.checkStart = false
          this.startDate = oldVal
        }else if(this.checkStart){
          try {
            this.$refs.menuStartDate.save(this.startDate)
          }catch(err){
            console.log(err);
          }
        }else{
          this.checkStart = true
        }
      },
      endDate: async function(newVal, oldVal) {
        if (new Date(newVal)<=new Date(this.startDate) || new Date(newVal)>new Date()){
          this.checkEnd = false
          this.endDate = oldVal
        }else if(this.checkEnd){
          try {
            this.$refs.menuEndDate.save(this.endDate)
          }catch(err){
            console.log(err);
          }
        }else{
          this.checkEnd = true
        }
      },
    },
    methods: {
      async get_postcode_mapping () {
        let response = await axios.post(generalService_dotnet+'General/get-postcodeMapping', {},{headers: header_token})
        if (response.status == 200) { this.postcode_mapping = response.data }
      },

      allowedDates: val => val <= new Date().toISOString().substr(0, 10),

      formatDate (date) {
        if (date.length == 0) return null

        if (date.length == 1) {
          const [year, month, day] = date[0].split('-')
          return `${day}-${month}-${year}`
        } else {
          const [year_from, month_from, day_from] = date[0].split('-')
          const [year_to, month_to, day_to] = date[1].split('-')
          if (date[0] < date[1]) {
            return `${day_from}-${month_from}-${year_from} ~ ${day_to}-${month_to}-${year_to}`
          } else {
            return `${day_to}-${month_to}-${year_to} ~ ${day_from}-${month_from}-${year_from}`
          }
        }
      },

      SelectItem (IsAll ,item, index, IsCancle) {
        if(IsAll == 'ALL'){
          if(this.checkboxAll.length > 0){

            this.checkboxList=[]
            for(var i=0; i< this.shop.table.length; i++){
              this.checkboxList.push('1')
            }
            this.shop.selected_to_make_invoice = [...this.shop.table]
            this.selected = [...this.shop.table];
          }else {
            this.selected = []
            for(var o=0; o< this.shop.table.length; o++){
              this.checkboxList[o] = '0'
            }

            this.shop.selected_to_make_invoice = []
          }


        } else if(IsAll == null) {
          if(IsCancle == null){
            var ind = this.selected.findIndex(p => p.ID == item.ID)
            this.selected.splice(ind, 1)
          } else {
            this.selected.push(item)
          }
          this.shop.selected_to_make_invoice = this.selected

        }
      },

      set_row_per_page () {
        this.row_per_page_select = parseInt(this.row_per_page_select_tmp)
      },

      async getInvoice() {
        this.loading = true
        if (this.startDate != null && this.endDate != null) {
          this.date_range = [this.startDate, this.endDate]
        }else {
          this.date_range = []
        }
        let response = await axios.post(financialService+'financialFC/getInvoice', {
          'BranchID': localStorage.getItem('Branch_BranchID'),
          'dateLength': this.date_range,
          'shop_id' : this.search_shops != null?this.search_shops['ID']:null,
        }, {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('token')}} )
        this.loading = false
        if (response.data.status == 'Success') {
          this.invoice['table'] = response.data.result
          this.invoice['key_table']++

          this.date_range = response.data.resultDate
          if (response.data.resultDate.length == 0) {
            var nowDate = new Date()
            this.endDate = nowDate.toISOString().substr(0, 10)
            nowDate.setDate(nowDate.getDate()-7)
            this.startDate = nowDate.toISOString().substr(0, 10)
          } else {
            this.startDate = this.date_range[0]
            this.endDate = this.date_range[1]
          }
        }
        this.invoice['showDatetime'] = false
        this.loading = false
      },
      async selectedShop() {
        this.loading = true
        this.shop['selected_to_make_invoice'] = []
        let response = await axios.post(financialService+'financialFC/getOrderMasterShop', {
          'BranchID': localStorage.getItem('Branch_BranchID'),
          'ShopID': this.shop['shop_select']['ID']
        }, {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('token')}} )
        this.loading = false
        if (response.data.status == 'Success') {
          this.shop['table'] = response.data.result
          this.shop['key_table']++
          this.page = 1
          this.page_tmp = null
          this.row_per_page = 50
          if (response.data.result.length != 0) {
            if(parseInt(response.data.result.length) > 50){
              this.pageLength = parseInt(response.data.result.length/this.row_per_page)
            }else {
              this.pageLength = 1
            }
          }else {
            this.pageLength = 1
          }

          this.page_loading = false
        }
        this.loading = false
      },
      confirmCreateInvoice() {
        // เช็คร้านห้ามซ้ำ
        var CheckShop = false
        if(this.shop.selected_to_make_invoice.length > 1){
          for (var n = 0; n < this.shop.selected_to_make_invoice.length; n++) {
              if (this.shop.selected_to_make_invoice[0]['ShopID'] != this.shop.selected_to_make_invoice[n]['ShopID']) {
                this.AlertWarning("เลือกออเดอร์เพียงร้านเดียว")
                CheckShop = true
                break;
              }
            }
        }
        if(CheckShop){
          return
        }


        this.confirmCreateInvoiceDialog = true
        this.dataConfirmCreateInvoice['dataList'] = []

        this.dataConfirmCreateInvoice['sendPriceMakeInvoice'] = 0
        for(var i=0; i<this.shop['selected_to_make_invoice'].length; i++) {
          if(this.shop['selected_to_make_invoice'][i]['DeliveryPrice'] != '' && this.shop['selected_to_make_invoice'][i]['DeliveryPrice'] != null){
            this.dataConfirmCreateInvoice['sendPriceMakeInvoice'] += parseFloat(this.shop['selected_to_make_invoice'][i]['DeliveryPrice'])
          }
        }
        this.dataConfirmCreateInvoice['sendPriceMakeInvoice'] = this.dataConfirmCreateInvoice['sendPriceMakeInvoice'].toFixed(2)

        this.dataConfirmCreateInvoice['remotePriceMakeInvoice'] = 0
        for(i=0; i<this.shop['selected_to_make_invoice'].length; i++) {
          if(this.shop['selected_to_make_invoice'][i]['RemotePrice'] != '' && this.shop['selected_to_make_invoice'][i]['RemotePrice'] != null){
            this.dataConfirmCreateInvoice['remotePriceMakeInvoice'] += parseFloat(this.shop['selected_to_make_invoice'][i]['RemotePrice'])
          }
        }
        this.dataConfirmCreateInvoice['remotePriceMakeInvoice'] = this.dataConfirmCreateInvoice['remotePriceMakeInvoice'].toFixed(2)

        this.dataConfirmCreateInvoice['codPriceMakeInvoice'] = 0
        for(i=0; i<this.shop['selected_to_make_invoice'].length; i++) {
          if(this.shop['selected_to_make_invoice'][i]['CODCharged'] != '' && this.shop['selected_to_make_invoice'][i]['CODCharged'] != null){
            this.dataConfirmCreateInvoice['codPriceMakeInvoice'] += parseFloat(this.shop['selected_to_make_invoice'][i]['CODCharged'])
          }
        }
        this.dataConfirmCreateInvoice['codPriceMakeInvoice'] = this.dataConfirmCreateInvoice['codPriceMakeInvoice'].toFixed(2)

        this.dataConfirmCreateInvoice['boxPriceMakeInvoice'] = 0
        for(i=0; i<this.shop['selected_to_make_invoice'].length; i++) {
          if(this.shop['selected_to_make_invoice'][i]['BoxPrice'] != '' && this.shop['selected_to_make_invoice'][i]['BoxPrice'] != null){
            this.dataConfirmCreateInvoice['boxPriceMakeInvoice'] += parseFloat(this.shop['selected_to_make_invoice'][i]['BoxPrice'])
          }
        }
        this.dataConfirmCreateInvoice['boxPriceMakeInvoice'] = this.dataConfirmCreateInvoice['boxPriceMakeInvoice'].toFixed(2)

        this.dataConfirmCreateInvoice['packPriceMakeInvoice'] = 0
        for(i=0; i<this.shop['selected_to_make_invoice'].length; i++) {
          if(this.shop['selected_to_make_invoice'][i]['PackPrice'] != '' && this.shop['selected_to_make_invoice'][i]['PackPrice'] != null){
            this.dataConfirmCreateInvoice['packPriceMakeInvoice'] += parseFloat(this.shop['selected_to_make_invoice'][i]['PackPrice'])
          }
        }
        this.dataConfirmCreateInvoice['packPriceMakeInvoice'] = this.dataConfirmCreateInvoice['packPriceMakeInvoice'].toFixed(2)

        this.dataConfirmCreateInvoice['discountMakeInvoice'] = 0
        for(i=0; i<this.shop['selected_to_make_invoice'].length; i++) {
          if(this.shop['selected_to_make_invoice'][i]['Discount'] != '' && this.shop['selected_to_make_invoice'][i]['Discount'] != null){
            this.dataConfirmCreateInvoice['discountMakeInvoice'] += parseFloat(this.shop['selected_to_make_invoice'][i]['Discount'])
          }
        }
        this.dataConfirmCreateInvoice['discountMakeInvoice'] = this.dataConfirmCreateInvoice['discountMakeInvoice'].toFixed(2)


        var tempSelected_to_make_invoice = []
        for(i=0; i<this.shop['selected_to_make_invoice'].length; i++){
          tempSelected_to_make_invoice.push(this.shop['selected_to_make_invoice'][i])
        }

        for(i=0; i<tempSelected_to_make_invoice.length; i++) {
          this.dataConfirmCreateInvoice['dataList'].push({
            'ShopID': tempSelected_to_make_invoice[i]['ShopID'],
            'Element': [ tempSelected_to_make_invoice[i] ]
          })
          for(var j=tempSelected_to_make_invoice.length-1; j>i; j--) {
            if (tempSelected_to_make_invoice[i]['ShopID']==tempSelected_to_make_invoice[j]['ShopID']) {
              this.dataConfirmCreateInvoice['dataList'][this.dataConfirmCreateInvoice['dataList'].length-1]['Element'].push(tempSelected_to_make_invoice[j])
              tempSelected_to_make_invoice.splice(j, 1)
            }
          }
        }
      },
      async createInvoice () {
        this.loading = true
        let response = await axios.post(financialService+'financialFC/createInvoice', {
          'BranchID': localStorage.getItem('Branch_BranchID'),
          'OrderList': this.dataConfirmCreateInvoice['dataList'],
          'sendPriceMakeInvoice': this.dataConfirmCreateInvoice['sendPriceMakeInvoice'],
          'remotePriceMakeInvoice': this.dataConfirmCreateInvoice['remotePriceMakeInvoice'],
          'codPriceMakeInvoice': this.dataConfirmCreateInvoice['codPriceMakeInvoice'],
          'boxPriceMakeInvoice': this.dataConfirmCreateInvoice['boxPriceMakeInvoice'],
          'packPriceMakeInvoice': this.dataConfirmCreateInvoice['packPriceMakeInvoice'],
          'staffId': localStorage.getItem('Branch_StaffID')
        }, {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('token')}} )
        this.loading = false
        if (response.data.status == 'Success') {
          this.AlertSuccess()
          this.confirmCreateInvoiceDialog = false
          this.selectedShop()

        }
      },
      calculateTotal(item) {
        var result = 0
        if(item.RemotePrice != '' && item.RemotePrice != null){
          result += parseFloat(item.RemotePrice)
        }
        if(item.CODCharged != '' && item.CODCharged != null){
          result += parseFloat(item.CODCharged)
        }
        if(item.PackPrice != '' && item.PackPrice != null){
          result += parseFloat(item.PackPrice)
        }
        if(item.BoxPrice != '' && item.BoxPrice != null){
          result += parseFloat(item.BoxPrice)
        }
        if(item.DeliveryPrice != '' && item.DeliveryPrice != null){
          result += parseFloat(item.DeliveryPrice)
        }
        if(item.Discount != '' && item.Discount != null){
          result += parseFloat(item.Discount)
        }
        return result.toFixed(2)
      },
      async openListOrderInvoice(row){
        this.invoice_id_row = row
        await this.get_data_aadress(row)
        this.loading = true
        let response = await axios.post(financialService+'financialFC/openListOrderInvoice', {
          'BranchID': localStorage.getItem('Branch_BranchID'),
          'ID': row['ID']
        }, {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('token')}} )
        if (response.data.status == 'Success') {
          this.listOrderInvoice['invoiceId'] = row['ID']
          this.listOrderInvoice['table'] = response.data.result
          var RemotePrice = 0
          var CODCharged = 0
          var PackPrice = 0
          var BoxPrice = 0
          var DeliveryPrice = 0
          var Discount = 0
          for(var i=0; i<response.data.result.length; i++){
            RemotePrice += response.data.result[i]['RemotePrice']==null?0:parseFloat(response.data.result[i]['RemotePrice'])
            CODCharged += response.data.result[i]['CODCharged']==null?0:parseFloat(response.data.result[i]['CODCharged'])
            PackPrice += response.data.result[i]['PackPrice']==null?0:parseFloat(response.data.result[i]['PackPrice'])
            BoxPrice += response.data.result[i]['BoxPrice']==null?0:parseFloat(response.data.result[i]['BoxPrice'])
            DeliveryPrice += response.data.result[i]['DeliveryPrice']==null?0:parseFloat(response.data.result[i]['DeliveryPrice'])
            Discount += response.data.result[i]['Discount']==null?0:parseFloat(response.data.result[i]['Discount'])
          }
          this.listOrderInvoice['table'].push({
            'RemotePrice': RemotePrice.toFixed(2),
            'CODCharged': CODCharged.toFixed(2),
            'PackPrice': PackPrice.toFixed(2),
            'BoxPrice': BoxPrice.toFixed(2),
            'DeliveryPrice': DeliveryPrice.toFixed(2),
            'Discount': Discount.toFixed(2)
          })
          this.ShopDetailInvoiceText = row['Name']
          this.listOrderInvoiceDialog = true
          this.loading = false
        }
      },

      async listOrderInvoice_export_excel () {
        let response = await axios.post(financialService+'financialFC/listOrderInvoice_export_excel', {
          'export_excel_data': this.listOrderInvoice.table
        }, {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('token')}} )
        if (response.data.status == 'Success') {
            var filename = response.data.this_path
            window.open(financialService+'financialFC/download/'+filename, "_blank");
            this.loading = false
        }
      },

      async Export_to_excel_data_invoice () {
        if(this.invoice.table.length == 0){
          this.AlertWarning("ไม่มีข้อมูล")
        } else {

          this.loading = true
          let response = await axios.post(financialService+'financialFC/Export_to_excel_data_invoice', {
            'data_export': this.invoice.table
          })
          if (response.data.status == 'Success') {
              var filename = response.data.this_path
              window.open(financialService+'financialFC/download/'+filename, "_blank");
              this.loading = false
          }
        }
      },

      AutoCreateInvoiceAllShop_confirm() {
        Swal.fire({
          position: "top",
          icon: 'warning',
          title: 'สร้างใบแจ้งหนี้ ใช่หรือไม่!!',
          // text: 'ยืนยัน ใช่หรือไม่!!',
          confirmButtonText: 'ยืนยัน',
          cancelButtonText: 'ยกเลิก',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          allowOutsideClick: false

        }).then((result) => {
          if (result.value) {
            this.AutoCreateInvoiceAllShop()
          }
        })
      },

      async AutoCreateInvoiceAllShop () {
        if(this.shop['table'].length !=0){
          this.loading = true
          let response = await axios.post(financialService+'financialFC/createInvoice_All_Shop', {
            'BranchID': localStorage.getItem('Branch_BranchID'),
            'staffId': localStorage.getItem('Branch_StaffID')
          }, {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('token')}} )
          if (response.data.status == 'Success') {
            if (response.data.cancleStatus == false) {
              // console.log('สร้างใบแจ้งหนี้ไม่ สำเร็จเนื่องจากไม่มี order ที่สร้างได้ !!');
              this.AlertError()
            }else {
              this.AlertSuccess()
              // this.selectedShop()
              this.tabSelected =1
            }
            this.loading = false
          }else {
            this.AlertError()
          }
        } else {
          this.AlertWarning("สร้างใบแจ้งหนี้ ไม่สำเร็จ เนื่องจากไม่มี order ที่สร้างได้")
        }

      },

      CancelInvoiceConfirm (invoiceID) {
        Swal.fire({
          position: "top",
          icon: 'warning',
          title: 'ยกเลิกใบแจ้งหนี้ ใช่หรือไม่!!',
          // text: 'ยืนยัน ใช่หรือไม่!!',
          confirmButtonText: 'ยืนยัน',
          cancelButtonText: 'ยกเลิก',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          allowOutsideClick: false

        }).then((result) => {
          if (result.value) {
            this.CancelInvoice(invoiceID)
          }
        })
      },

      async CancelInvoice (invoiceID) {
        this.loading = true
        let response = await axios.post(financialService+'financialFC/cancleInvoice', {
          'BranchID': localStorage.getItem('Branch_BranchID'),
          'invoiceID': invoiceID,
          'staffId': localStorage.getItem('Branch_StaffID')
        }, {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('token')}} )
        if (response.data.status == 'Success') {
          if (response.data.resultStatus == false) {
            // console.log('สร้างใบแจ้งหนี้ไม่ สำเร็จเนื่องจากไม่มี order ที่สร้างได้ !!');
            this.AlertWarning("สร้างใบแจ้งหนี้ ไม่สำเร็จ เนื่องจากไม่มี order ที่สร้างได้")
          }else {
            this.AlertSuccess()
            this.getInvoice()
          }
          this.loading = false
        }
      },

      // dialog in voice edit address shop and branch

      async get_data_aadress (item) {
        this.dialog_invoice_invoiceID = item['ID']
        this.dialog_invoice_ShopID = item['ShopID']
        this.dialog_invoice_BranchID = localStorage.getItem('Branch_BranchID')
        var ShopAddressID = null
        var BranchAddressID = null

        if(item['ShopAddressInvoiceID'] != null){ShopAddressID = item['ShopAddressInvoiceID']}
        if(item['BranchAddressInvoiceID'] != null){BranchAddressID = item['BranchAddressInvoiceID']}

        this.loading = true
        let response = await axios.post(manageShopService+'manageShop/get_data_address_detail_invoice', {
          'ShopAddressID': ShopAddressID,
          'BranchAddressID': BranchAddressID
        })
        if (response.data.status == 'Success'){
          // address shop >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
          if (response.data.DataShopAddressInvoice.length>0){

            this.Shop_FullAddress_text = response.data.DataShopAddressInvoice[0]['FullAddress']

            var ShopAddressInvoice = response.data.DataShopAddressInvoice[0]
            this.ShopAddressInvoice.ID = ShopAddressInvoice.ID
            this.ShopAddressInvoice.Name = ShopAddressInvoice.Name
            this.ShopAddressInvoice.Phone = ShopAddressInvoice.Phone
            this.ShopAddressInvoice.IDCardNumber = ShopAddressInvoice.IDCardNumber
            this.ShopAddressInvoice.TextAddress = ShopAddressInvoice.Address
            this.ShopAddressInvoice.IsDefault = ShopAddressInvoice.IsDefault
            this.ShopAddressInvoice.Address = {
               "id": ShopAddressInvoice['PostcodeMappingID'],
               "district": ShopAddressInvoice['SubDistrict'],
               "amphoe": ShopAddressInvoice['District'],
               "province": ShopAddressInvoice['Province'],
               "zipcode": ShopAddressInvoice['Postcode'],
               "provinceid": 1
            }

            // data temp
            this.ShopAddressInvoice_tmp.ID = ShopAddressInvoice.ID
            this.ShopAddressInvoice_tmp.Name = ShopAddressInvoice.Name
            this.ShopAddressInvoice_tmp.Phone = ShopAddressInvoice.Phone
            this.ShopAddressInvoice_tmp.IDCardNumber = ShopAddressInvoice.IDCardNumber
            this.ShopAddressInvoice_tmp.TextAddress = ShopAddressInvoice.Address
            this.ShopAddressInvoice_tmp.IsDefault = ShopAddressInvoice.IsDefault
            this.ShopAddressInvoice_tmp.Address = {
               "id": ShopAddressInvoice['PostcodeMappingID'],
               "district": ShopAddressInvoice['SubDistrict'],
               "amphoe": ShopAddressInvoice['District'],
               "province": ShopAddressInvoice['Province'],
               "zipcode": ShopAddressInvoice['Postcode'],
               "provinceid": 1
            }
          } else {
            this.ShopAddressInvoice= {
              "ID":null,
              "Name": null,
              "Phone": null,
              "IDCardNumber": null,
              "TextAddress": null,
              "Address": null,
              "PostcodeMappingID": null,
              "IsDefault": true,
            }

            this.ShopAddressInvoice_tmp= {
              "ID":null,
              "Name": null,
              "Phone": null,
              "IDCardNumber": null,
              "TextAddress": null,
              "Address": null,
              "PostcodeMappingID": null,
              "IsDefault": true,
            }

            this.Shop_FullAddress_text= null
          }

          // address branch >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
          if (response.data.DataBranchAddressInvoice.length>0){
            // console.log(response.data.DataBranchAddressInvoice);

            this.branch_FullAddress_text = response.data.DataBranchAddressInvoice[0]['FullAddress']

            var BranchAddressInvoice = response.data.DataBranchAddressInvoice[0]
            this.BranchAddressInvoice.ID = BranchAddressInvoice.ID
            this.BranchAddressInvoice.Name = BranchAddressInvoice.Name
            this.BranchAddressInvoice.Phone = BranchAddressInvoice.Phone
            this.BranchAddressInvoice.IDCardNumber = BranchAddressInvoice.IDCardNumber
            this.BranchAddressInvoice.TextAddress = BranchAddressInvoice.Address
            this.BranchAddressInvoice.IsDefault = BranchAddressInvoice.IsDefault
            this.BranchAddressInvoice.Address = {
               "id": BranchAddressInvoice['PostcodeMappingID'],
               "district": BranchAddressInvoice['SubDistrict'],
               "amphoe": BranchAddressInvoice['District'],
               "province": BranchAddressInvoice['Province'],
               "zipcode": BranchAddressInvoice['Postcode'],
               "provinceid": 1
            }

            // data temp
            this.BranchAddressInvoice_tmp.ID = BranchAddressInvoice.ID
            this.BranchAddressInvoice_tmp.Name = BranchAddressInvoice.Name
            this.BranchAddressInvoice_tmp.Phone = BranchAddressInvoice.Phone
            this.BranchAddressInvoice_tmp.IDCardNumber = BranchAddressInvoice.IDCardNumber
            this.BranchAddressInvoice_tmp.TextAddress = BranchAddressInvoice.Address
            this.BranchAddressInvoice_tmp.IsDefault = BranchAddressInvoice.IsDefault
            this.BranchAddressInvoice_tmp.Address = {
               "id": BranchAddressInvoice['PostcodeMappingID'],
               "district": BranchAddressInvoice['SubDistrict'],
               "amphoe": BranchAddressInvoice['District'],
               "province": BranchAddressInvoice['Province'],
               "zipcode": BranchAddressInvoice['Postcode'],
               "provinceid": 1
            }
          } else {
            this.BranchAddressInvoice= {
              "ID":null,
              "Name": null,
              "Phone": null,
              "IDCardNumber": null,
              "TextAddress": null,
              "Address": null,
              "PostcodeMappingID": null,
              "IsDefault": true,
            }

            this.BranchAddressInvoice_tmp= {
              "ID":null,
              "Name": null,
              "Phone": null,
              "IDCardNumber": null,
              "TextAddress": null,
              "Address": null,
              "PostcodeMappingID": null,
              "IsDefault": true,
            }
            this.branch_FullAddress_text= null
          }



          this.loading = false
          } else {
            this.AlertError()
            this.loading = false
          }
        },

      async AddandEditShopAddressInvoice (type) {
        if(this.ShopAddressInvoice.Name == null || this.ShopAddressInvoice.Name == ''){
          this.$refs.refShopAddressInvoiceName.focus()
          return
        } else if (this.ShopAddressInvoice.Phone == null || this.ShopAddressInvoice.Phone == '') {
          this.$refs.refShopAddressInvoicePhone.focus()
          return
        } else if (this.ShopAddressInvoice.TextAddress == null || this.ShopAddressInvoice.TextAddress == '') {
          this.$refs.refShopAddressInvoiceTextAddress.focus()
          return
        } else if (this.ShopAddressInvoice.Address == null || this.ShopAddressInvoice.Address == '') {
          this.$refs.refShopAddressInvoiceAddress.focus()
          return
        }
        // เช็คข้อมูลว่ามีการเปลี่ยนแปลงหรือไม่เพราะถ้ากด "ตกลง" จะเป็นการ add ที่อยู่ invoice ใหม่
        var IsAdd = false
        if(
          (this.ShopAddressInvoice.Name == this.ShopAddressInvoice_tmp.Name)
          && (this.ShopAddressInvoice.IDCardNumber == this.ShopAddressInvoice_tmp.IDCardNumber)
          && (this.ShopAddressInvoice.Phone == this.ShopAddressInvoice_tmp.Phone)
          && (this.ShopAddressInvoice.TextAddress == this.ShopAddressInvoice_tmp.TextAddress)
          && (this.ShopAddressInvoice.Address.id == this.ShopAddressInvoice_tmp.Address.id) ){
          IsAdd = false
          // this.listOrderInvoiceDialog = false
          this.shopDisibled = true
        } else {
          IsAdd = true
        }
        if(IsAdd){
          this.loading = true
          let response = await axios.post(manageShopService+'manageShop/AddAndUpdateShopAddressInvoice', {
            'ShopID': this.dialog_invoice_ShopID,
            'TypeAction': type,
            'ShopAddressInvoice' : this.ShopAddressInvoice
          })
          if (response.data.status == 'Success'){
            this.shopDisibled = true
            // this.listOrderInvoiceDialog = false
            await this.update_adress_shop_and_branch(this.dialog_invoice_invoiceID, this.dialog_invoice_ShopID, true, null) //(invoice, shop, branch)
            await this.getInvoice()
            // แก้ไข ที่อยู่
            var index_tmp
            index_tmp = this.invoice['table'].findIndex(x => x.ID == this.invoice_id_row.ID)
            if(index_tmp != -1 && this.invoice_id_row != null){
              this.openListOrderInvoice(this.invoice['table'][index_tmp])
            }
          } else {
            this.AlertError()
            this.loading = false
          }
        }
      },

      async AddandEditBranchAddressInvoice (type) {
        if(this.BranchAddressInvoice.Name == null || this.BranchAddressInvoice.Name == ''){
          this.$refs.refBranchAddressInvoiceName.focus()
          return
        } else if (this.BranchAddressInvoice.Phone == null || this.BranchAddressInvoice.Phone == '') {
          this.$refs.refBranchAddressInvoicePhone.focus()
          return
        } else if (this.BranchAddressInvoice.TextAddress == null || this.BranchAddressInvoice.TextAddress == '') {
          this.$refs.refBranchAddressInvoiceTextAddress.focus()
          return
        } else if (this.BranchAddressInvoice.Address == null || this.BranchAddressInvoice.Address == '') {
          this.$refs.refBranchAddressInvoiceAddress.focus()
          return
        }

        // เช็คข้อมูลว่ามีการเปลี่ยนแปลงหรือไม่เพราะถ้ากด "ตกลง" จะเป็นการ add ที่อยู่ invoice ใหม่
        var IsAdd = false
        if((this.BranchAddressInvoice.Name == this.BranchAddressInvoice_tmp.Name) && (this.BranchAddressInvoice.IDCardNumber == this.BranchAddressInvoice_tmp.IDCardNumber) && (this.BranchAddressInvoice.Phone == this.BranchAddressInvoice_tmp.Phone) && (this.BranchAddressInvoice.TextAddress == this.BranchAddressInvoice_tmp.TextAddress) && (this.BranchAddressInvoice.Address.id == this.BranchAddressInvoice_tmp.Address.id)){
          IsAdd = false
          this.branchDisibled = true
          // this.listOrderInvoiceDialog = false
        } else {
          IsAdd = true
        }

        if(IsAdd){

          this.loading = true
          let response = await axios.post(manageShopService+'manageShop/AddAndUpdateBranchAddressInvoice', {
            'BranchID': this.dialog_invoice_BranchID,
            'TypeAction': type,
            'BranchAddressInvoice' : this.BranchAddressInvoice
          })
          if (response.data.status == 'Success'){
            this.branchDisibled = true
            // this.listOrderInvoiceDialog = false
            await this.update_adress_shop_and_branch(this.dialog_invoice_invoiceID, this.dialog_invoice_ShopID, null, true) //(invoice, shop, branch)
            await this.getInvoice()

            // แก้ไข ที่อยู่
            var index_tmp
            index_tmp = this.invoice['table'].findIndex(x => x.ID == this.invoice_id_row.ID)
            if(index_tmp != -1 && this.invoice_id_row != null){
              this.openListOrderInvoice(this.invoice['table'][index_tmp])
            }


          } else {
            this.AlertError()
            this.loading = false
          }
        }
      },

      async update_adress_shop_and_branch (invoiceID, shop_id, IsShopAddress, IsBranchAddress) {

        this.loading = true
        let response = await axios.post(financialService+'financialFC/invoice_update_address', {
          'invoiceID': invoiceID,
          'shop_id': shop_id,
          'branch_id': localStorage.getItem('Branch_BranchID'),
          'IsShopAddress': IsShopAddress,
          'IsBranchAddress' : IsBranchAddress
        })
        if (response.data.status == 'Success'){
          this.loading = false
        } else {
          this.AlertError()
          this.loading = false
        }
      },

      async PrintPreview(invoiceID){
        this.loading = true
        var dataPrint = []
        let response = await axios.post(financialService+'financialFC/get_invoice_print', {
          'invoiceID': invoiceID
        })
        if (response.data.status == 'Success'){
          dataPrint = response.data.result
          localStorage.setItem('dataPrint',  JSON.stringify(dataPrint))
          this.loading = false
        } else {
          this.AlertError()
          this.loading = false
        }

        window.open('/print-invoice');
      },
      FormatInvoice,
      FormatOrder,
      formatMoney,
      isNumberWNoDot,
      AlertSuccess,
      AlertWarning,
      AlertError,
    }
  }
</script>

<style scoped>
  ::v-deep .v-data-table__empty-wrapper {
    display: none;
  }
  .mobile-none {
    display: ;
  }
  .pc-none {
    display: none;
  }
  @media (max-width: 599px) {
    .mobile-none {
      display: none;
    }
    .pc-none {
      display: ;
    }
  }
</style>
